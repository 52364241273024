<template>
    <div class="page">
        <div class="page_header">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>短视频</el-breadcrumb-item>
                <el-breadcrumb-item>威有海量媒体资源</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="page_headerSecondary">
            <div></div>
            <div class="">
                <!-- <button @click="openAdd_()">添加一级</button> -->
                <VyAddBtn @click.native="openAdd_()"></VyAddBtn>
            </div>
        </div>
        <!-- 列表 -->
        <div class="page_content">
            <el-table :data="tableData" row-key="id" border>
                <el-table-column prop="name" align="center" label="账号名"></el-table-column>
                <el-table-column prop="head_image" align="center" label="头像">
                    <template slot-scope="{ row }">
                        <img :src="row.head_image" style="width: 100%" />
                    </template>
                </el-table-column>
                <el-table-column prop="fans" align="center" label="粉丝"></el-table-column>
                <el-table-column prop="hot" align="center" label="热门">
                    <template slot-scope="{ row }">
                        <span v-if="row.hot != undefined" :style="{ color: row.hot ? '#b66dff' : '#fe7c96' }">{{
                            row.hot ? "是" : "否"
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="is_top" align="center" label="置顶">
                    <template slot-scope="{ row }">
                        <span :style="{ color: row.is_top ? '#b66dff' : '#fe7c96' }">{{ row.is_top ? "是" : "否" }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="status" align="center" label="状态">
                    <template slot-scope="{ row }">
                        <span :style="{ color: row.status ? '#b66dff' : '#fe7c96' }">{{ row.status ? "显示" : "隐藏" }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="{ row }">
                        <div v-if="row.pid == 0">
                            <!-- <button @click="openAdd(row)">添加</button> -->
                            <VyAddBtn @click.native="openAdd(row)" btnType="二级" />
                        </div>
                        <div class="_operate">
                            <!-- 编辑子类 -->
                            <button @click="openSet(row)" v-if="row.pid">编辑</button>
                            <!-- 编辑父类 -->
                            <button @click="openSet_(row)" v-if="row.pid == 0">编辑</button>
                        </div>
                        <div class="_delete">
                            <!-- 删除子类 -->
                            <button @click="del(row)" v-if="row.pid">删除</button>
                            <!-- 删除父类 -->
                            <button @click="del_(row)" v-if="row.pid == 0">删除</button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 添加或编辑子弹框 -->
        <el-dialog
            :title="form.id ? '编辑' : '添加'"
            :visible.sync="dialogShow"
            width="70%"
            :show-close="false"
            :before-close="bfc"
        >
            <el-form :model="form" label-width="90px">
                <el-form-item label="账号">
                    <el-input v-model.trim="form.name"></el-input>
                </el-form-item>
                <el-form-item label="简介">
                    <el-input type="textarea" autosize resize="none" v-model.trim="form.intro"></el-input>
                </el-form-item>
                <el-form-item label="人气值(%)">
                    <el-input-number v-model="form.popularity" :min="0" :max="100"></el-input-number>
                </el-form-item>
                <el-form-item label="覆盖率(%)">
                    <el-input-number v-model="form.cover" :min="0" :max="100"></el-input-number>
                </el-form-item>
                <el-form-item label="性价比(%)">
                    <el-input-number v-model="form.cost_performance" :min="0" :max="100"></el-input-number>
                </el-form-item>
                <el-form-item label="传播力(%)">
                    <el-input-number v-model="form.propagate" :min="0" :max="100"></el-input-number>
                </el-form-item>
                <el-form-item label="知名度(%)">
                    <el-input-number v-model="form.renown" :min="0" :max="100"></el-input-number>
                </el-form-item>
                <el-form-item label="粉丝">
                    <el-input-number v-model="form.fans" :min="0"></el-input-number>
                </el-form-item>
                <el-form-item label="最高观众量">
                    <el-input-number v-model="form.highest" :min="0"></el-input-number>
                </el-form-item>
                <el-form-item label="状态">
                    <el-switch
                        class="sw"
                        v-model="form.status"
                        active-color="#7781f1"
                        inactive-color="#ff4949"
                        active-text="显示"
                        inactive-text="隐藏"
                        :active-value="1"
                        :inactive-value="0"
                    ></el-switch>
                </el-form-item>
                <el-form-item label="热门">
                    <el-switch
                        class="sw"
                        v-model="form.hot"
                        active-color="#7781f1"
                        inactive-color="#ff4949"
                        active-text="显示"
                        inactive-text="隐藏"
                        :active-value="1"
                        :inactive-value="0"
                    ></el-switch>
                </el-form-item>
                <el-form-item label="置顶">
                    <el-switch
                        class="sw"
                        v-model="form.is_top"
                        active-color="#7781f1"
                        inactive-color="#ff4949"
                        active-text="显示"
                        inactive-text="隐藏"
                        :active-value="1"
                        :inactive-value="0"
                    ></el-switch>
                </el-form-item>
                <el-form-item label="视频">
                    <upload-img :src="form.video" @removeSrc="() => (form.video = '')" ref="video"></upload-img>
                </el-form-item>
                <el-form-item label="头像">
                    <upload-img :src="form.head_image" @removeSrc="() => (form.head_image = '')" ref="head_image"></upload-img>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogShow = false">取 消</el-button>
                <el-button type="primary" @click="yes">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 添加或编辑子弹框 -->
        <el-dialog
            :title="form_.id ? '编辑' : '添加'"
            :visible.sync="dialogShow_"
            width="70%"
            :show-close="false"
            :before-close="bfc"
        >
            <el-form :model="form_" label-width="90px">
                <el-form-item label="分类名">
                    <el-input v-model.trim="form_.name"></el-input>
                </el-form-item>
                <el-form-item label="状态">
                    <el-switch
                        class="sw"
                        v-model="form_.status"
                        active-color="#7781f1"
                        inactive-color="#ff4949"
                        active-text="显示"
                        inactive-text="隐藏"
                        :active-value="1"
                        :inactive-value="0"
                    ></el-switch>
                </el-form-item>
                <el-form-item label="置顶">
                    <el-switch
                        class="sw"
                        v-model="form_.is_top"
                        active-color="#7781f1"
                        inactive-color="#ff4949"
                        active-text="显示"
                        inactive-text="隐藏"
                        :active-value="1"
                        :inactive-value="0"
                    ></el-switch>
                </el-form-item>
                <el-form-item label="图标">
                    <upload-img :src="form_.image_icon" @removeSrc="() => (form_.image_icon = '')" ref="image_icon"></upload-img>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogShow_ = false">取 消</el-button>
                <el-button type="primary" @click="yes_">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import axios from "axios";
import uploadImg from "@/components/customs/uploadImg.vue";
import qs from "qs";
import VyAddBtn from "@/components/customs/VyAddBtn.vue";
export default {
    components: { uploadImg, VyAddBtn },
    data() {
        return {
            tableData: [],
            form: {},
            dialogShow: false,
            form_: {},
            dialogShow_: false,
        };
    },
    async created() {
        this.tableData = await this.getData();
    },
    methods: {
        async getData() {
            let { data } = await axios.get("/api/video/svrclist");
            return data;
        },
        openAdd(row) {
            this.form = {
                name: "",
                intro: "",
                video: "",
                head_image: "",
                popularity: 0,
                cover: 0,
                cost_performance: 0,
                propagate: 0,
                renown: 0,
                fans: 0,
                status: 1,
                hot: 0,
                is_top: 0,
                pid: row.id,
                highest: 0,
            };
            this.dialogShow = true;
        },
        openSet(row) {
            this.form = { ...row };
            this.dialogShow = true;
        },
        async yes() {
            let { form } = this;
            form.video = this.$refs.video.url;
            form.head_image = this.$refs.head_image.url;
            if (form.id) {
                // 编辑
                var { data } = await axios.put("/api/video/svrup", qs.stringify(form));
            } else {
                // 添加
                var { data } = await axios.post("/api/video/svradd", qs.stringify(form));
            }
            this.$message({
                message: data.msg,
                type: data.code == 200 ? "success" : "error",
            });
            this.tableData = await this.getData();
            this.dialogShow = false;
        },
        async del({ id }) {
            let s = await this.$confirm("是否确认删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => true)
                .catch(() => false);
            if (!s) return;
            let { data } = await axios.delete("/api/video/svrdel", { params: { id } });
            this.$message({
                message: data.msg,
                type: data.code == 200 ? "success" : "error",
            });
            this.tableData = await this.getData();
        },
        openAdd_(row) {
            this.form_ = {
                name: "",
                status: 1,
                is_top: 0,
                image_icon: "",
            };
            this.dialogShow_ = true;
        },
        openSet_(row) {
            this.form_ = {
                ...row,
                image_icon: row.image,
            };
            this.dialogShow_ = true;
        },
        async yes_() {
            let { form_ } = this;
            form_.image = this.$refs.image_icon.url;
            if (form_.id) {
                // 编辑
                var { data } = await axios.put("/api/video/svrcup", qs.stringify(form_));
            } else {
                // 添加
                var { data } = await axios.post("/api/video/svrcadd", qs.stringify(form_));
            }
            this.$message({
                message: data.msg,
                type: data.code == 200 ? "success" : "error",
            });
            this.tableData = await this.getData();
            this.dialogShow_ = false;
        },
        async del_({ id }) {
            let s = await this.$confirm("是否确认删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => true)
                .catch(() => false);
            if (!s) return;
            let { data } = await axios.delete("/api/video/svrcdel", { params: { id } });
            this.$message({
                message: data.msg,
                type: data.code == 200 ? "success" : "error",
            });
            this.tableData = await this.getData();
        },
    },
};
</script>

<style lang="less" scoped>
.page {
    .page_content {
        margin-top: 20px;
        .el-table {
            border-radius: 20px;
        }
    }
    .page_pagination {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        /deep/.el-pagination {
            border-radius: 50px;
            overflow: hidden;
            padding: 0px;
        }
    }
    .page_headerSecondary {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
.sw {
    /deep/.el-switch__label.is-active {
        color: #7781f1 !important;
    }
}
</style>
